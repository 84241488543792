<template>
  <div class="column topbg">
    <img class="imgsize44 ml25 mt60" src="~images/fanhui.png" alt="" @click="onBack">
    <div class="column align-center">
        <img class="logosize mt60" src="~images/logo.png" alt="">
        <img class="size mt218" src="~images/zc.png" alt="" @click="onReg">
        <img class="size" src="~images/phone.png" alt="" @click="onLogin">
        <img class="size" src="~images/wx.png" alt="">
        <div class="row align-center font22 f-666666 mt143 mb32">
            <span>我已阅读并同意</span>
            <span class="font22 f-1A1A1A">《用户服务协议》</span>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import MyHeader from './childCmps/MyHeader'
export default {
  name: 'LoginPage',
  components: {
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onReg() {
      this.$router.push('/reg');
    },
    onLogin() {
      this.$router.push('/login');
    }
  }
}
</script>
<style  scoped>

.topbg{
    background: url(~images/beijing.png) no-repeat; 
    background-size: 100% 100%; 
    height: 100vh; 
    width: 100%;
}
.logosize{
    width: 5.066667rem;
    height: 5.573333rem;
}
.size{
    width: 6.106667rem;
    height: 1.333333rem;
}

</style>
